<!-- 成绩复核列表 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";



  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,


    },
    data() {
      return {
        title: "证书邮寄列表",
        items: [{
            text: "考试报名"
          },
          {
            text: "报名记录",
            active: true
          }
        ],
        defaultvalue: 3,
        currentpage: 3
      };
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash"><span class="mr-2">以下是已查询到的证书列表</span>
              <a href="/scoreSend"><button type="button"
                class="btn btn-info h30"><i class="iconfont icon-plus-circle mr-2 font-size-18"></i>提交邮寄申请</button></a>
            </h4>
            <div class="table-responsive border mt-3">
              <table class="table table-has-bg light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%">序号</th>
                    <th>证书名称</th>
                    <th>邮寄地址</th>
                    <th style="width:5%">费用</th>
                    <th style="width:10%">缴费状态</th>
                    <th style="width:20%">快递单号</th>
                    <th style="width:7%">寄出时间 </th>
                    <th style="width:5%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td> 2020年度卫生、中医药专业副高级技术资格证书 </td>
                    <td>四川省南南充市顺庆区富江路235号 刘晓川 138013881388</td>
                    <td>25</td>
                    <td>已缴费</td>
                    <td><div class="blue-font">邮政EMS 67632E87228832 追踪状态</div></td>
                    <td>2021-12-06</td>
                    <td><button type="button" class="btn btn-info h30">详情</button></td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td> 2020年度卫生、中医药专业副高级技术资格证书 </td>
                    <td>四川省南南充市顺庆区富江路235号 刘晓川 138013881388</td>
                    <td>25</td>
                    <td>待缴费</td>
                    <td> --</td>
                    <td>--</td>
                    <td><a href=""><button type="button" class="btn btn-info h30 mb-2">缴费</button></a><br />
                    <button type="button" class="btn btn-danger h30">撤销</button></td>
                  </tr>
                  

                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
